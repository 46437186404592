//
// _helper.scss
//

// Border Style
$border-style-mixing: double, dashed, groove, outset, ridge, dotted, inset;
@each $border-style-mixing in $border-style-mixing {
    .border-#{$border-style-mixing} {
        border-style: #{$border-style-mixing} !important;
    }
    .border-top-#{$border-style-mixing} {
        border-top-style: #{$border-style-mixing} !important;
    }
    .border-bottom-#{$border-style-mixing} {
        border-bottom-style: #{$border-style-mixing} !important;
    }
    .border-end-#{$border-style-mixing} {
        border-right-style: #{$border-style-mixing} !important;
    }
    .border-start-#{$border-style-mixing} {
        border-left-style: #{$border-style-mixing} !important;
    }
    
    // List Group Flush Style
    .list-group-flush {
        &.border-#{$border-style-mixing} { 
            border: none !important;
            .list-group-item {
                border-style: #{$border-style-mixing} !important;
            }
        }
    }
}

// Font Family

.ff-base {
    font-family: var(--#{$prefix}font-family-primary);
}

.ff-secondary {
    font-family: var(--#{$prefix}font-family-secondary);
}

// Background pattern
.bg-pattern {
    background: url("/themes/b/images/modal-bg.png") $modal-content-bg;
}

// Fixed minimum width
.w-xs {
    min-width: 80px;
}

.w-sm {
    min-width: 95px;
}

.w-md {
    min-width: 110px;
}

.w-lg {
    min-width: 140px;
}

.w-xl {
    min-width: 160px;
}

// Icons Sizes
.icon-xs {
    height: 16px;
    width: 16px;
}

.icon-sm {
    height: 18px;
    width: 18px;
}

.icon-md {
    height: 22px;
    width: 22px;
}

.icon-lg {
    height: 24px;
    width: 24px;
}

.icon-xl {
    height: 28px;
    width: 28px;
}

.icon-xxl {
    height: 32px;
    width: 32px;
}

// Dual Icons
.icon-dual {
    color: $gray-500;
    fill: rgba($gray-500,0.16);
}

@each $state in map-keys($theme-colors) {
    .icon-dual-#{$state} {
        color: var(--#{$prefix}#{$state});
        fill: rgba(var(--#{$prefix}#{$state}-rgb), 0.16);
    }
}

// Search 
.search-box{
    position: relative;
    .form-control{
        padding-left: 40px;
    }
    .search-icon{
        font-size: 14px;
        position: absolute;
        left: 13px;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        color: var(--#{$prefix}secondary-color);
    }
}


// overlay

.bg-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: 0.7;
    background-color: $black;
}

// Btn Customizer

.customizer-setting {
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 1000;
}

// 
code {
    user-select: all;
}

.layout-rightside {
    width: 280px;
    margin-right: calc(#{$grid-gutter-width} * -0.9);
    margin-top: calc(1px - #{$grid-gutter-width} * 1.1);
    height: calc(100% + #{$grid-gutter-width} * 1.1);
}

.layout-rightside-col {

    @media (max-width: 1699.98px) { 
        display: none;
        position: fixed !important;
        height: 100vh;
        right: 0px;
        top: 0px;
        bottom: 0px;
        z-index: 1004;
        .overlay{
            position: fixed;
            top: 0;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-color: rgba($dark, 0.2);
           
        }
        .layout-rightside {
            margin-top: 0px;
            height: 100%;
            margin-left: auto;
        }
        .card-body {
            overflow-y: auto;
            padding-bottom: 1rem !important;
        }
    }
}

:is([data-layout="horizontal"], [data-layout-style=detached]) {
    @media (min-width: 1700px) {
        .layout-rightside{
            margin-top: calc(28px - #{$grid-gutter-width});
            margin-right: 0;
            height: calc(100% - calc(#{$grid-gutter-width} * 0.5));
        }
    }
}

[data-layout="semibox"] {
    .layout-rightside {
        margin-right: 0px;
    }
}

// text-truncate-two-lines
.text-truncate-two-lines{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
}

// Favorite button 
.favourite-btn{
    border-color: transparent;
    .ri-star-fill{
        color: var(--#{$prefix}secondary-color);
    }
    &.active{
        border-color: transparent;
        .ri-star-fill{
            color: var(--#{$prefix}warning);
            
            &:before{
                content: "\f186";
            }
        }
    }
}
// card
.card-logo-light{
    display: var(--#{$prefix}card-logo-light);
}

.card-logo-dark{
    display: var(--#{$prefix}card-logo-dark);
}

#back-to-top {
    position: fixed;
    bottom: 100px;
    right: 28px;
    transition: all 0.5s ease;
    display: none;
    z-index: 1000;
    &:hover {
        animation: fade-up 1.5s infinite linear;
    }
}

@keyframes fade-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
}